import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastService } from 'src/app/core/services/misc/toast.service';

@Component({
  selector: 'app-add-lungs',
  templateUrl: './add-lungs.component.html',
  styleUrls: ['./add-lungs.component.scss']
})
export class AddLungsComponent implements OnInit {

  lungs_form = new FormGroup({
    name: new FormControl('', Validators.compose([Validators.required,])),
    date: new FormControl(''),
    selections: new FormControl(''),
  });

  constructor(
    private matDialogRef: MatDialogRef<any>,
    private toast: ToastService,
  ) { }

  ngOnInit(): void {
  }

  save() {
    if (this.lungs_form.value.name) {
      const object = this.lungs_form.value;
      object.is_default = false
      object.selected = true
      console.log(object)
      this.matDialogRef.close(object);
    } else {
      this.toast.showError('Please Enter Name')
    }
  }
  
}
