import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MasterDataService } from "../../../core/services/curd/master-data.service";
import Swal from "sweetalert2";

@Component({
  selector: 'app-master-data',
  templateUrl: './master-data.component.html',
  styleUrls: ['./master-data.component.scss']
})
export class MasterDataComponent implements OnInit {

  value = '';
  show_create_input = false;
  search_value = '';
  data_array = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { type: string, array: [], show_create_input: boolean },
    private masterData: MasterDataService,
    private matDialogRef: MatDialogRef<any>,
  ) {
    this.show_create_input = data.show_create_input;
  }

  ngOnInit(): void {
    this.data_array = this.data.array
    console.log(this.data_array)
  }

  save() {
    const input = {
      type: this.data.type,
      name: this.value,
      synced: false
    }
    console.log(input)
    this.masterData.create(input).then(res => {
    console.log(res)

      this.matDialogRef.close(this.value);
    })
  }

  editMasterDataItem(item) {
    item.editing = true;
  }

  updateMasterDataItem(item) {
    const input = {
      type: this.data.type,
      name: item.name,
      synced: false
    }
    const request_object = {
      _id: item._id,
      input_data: input
    }
    this.masterData.update(request_object).then(res => {
      item.editing = false;
    })

  }

  deleteMasterDataItem(item, i) {
    console.log(item)
    console.log(i)
    Swal.fire({
      title: "Do you want to delete?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0b51b7",
      cancelButtonColor: "#ef4d56",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        this.masterData.delete(item._id).then(res => {
          this.data_array.splice(i, 1);
          this.data.array.splice(i, 1);
        })
      }
    });
  }

  showCreateInput() {
    if (this.show_create_input == false) {
      this.show_create_input = true
    } else {
      this.show_create_input = false
    }
  }

  onFilterChange() {
    if (this.search_value) {
      //@ts-ignore
      this.data_array = this.data_array.filter(obj => obj.name.toLowerCase().includes(this.search_value.toLowerCase()));
    }
    else {
      this.data_array = this.data.array
    }
  }
}
