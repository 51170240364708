<div class="topbar">
  <nav class="navbar-custom" id="navbar-custom">
    <ul class="list-unstyled topbar-nav float-end mb-0">
      <li>
        <button
          type="button"
          class="btn btn-primary same-width"
          (click)="triggerModal(changeHospital, 'md')"
        >
          Select Hopsital
        </button>
      </li>
      <li>
        <button
          type="button"
          class="btn btn-primary same-width"
          (click)="triggerModal(modalData)"
        >
          Create New Appointment
        </button>
      </li>
      <li class="dropdown m-lg-5">
        <a
          class="nav-link dropdown-toggle nav-user"
          data-bs-toggle="dropdown"
          href="#"
          role="button"
          aria-haspopup="false"
          aria-expanded="false"
        >
          <div class="d-flex align-items-center">
            <div>
              <small class="d-none d-md-block font-11">Welcome</small>
              <span class="d-none d-md-block fw-semibold font-12"
                ><span class="user-name">{{ doctor_name }}</span>
                <i class="mdi mdi-chevron-down"></i
              ></span>
            </div>
          </div>
        </a>
        <div class="dropdown-menu dropdown-menu-end">
          <a class="dropdown-item" (click)="profileInfo()"
            ><i class="ti ti-user font-16 me-1 align-text-bottom"></i>Profile</a
          >
          <div class="dropdown-divider mb-0"></div>
          <a class="dropdown-item" routerLink="/"
            ><i class="ti ti-power font-16 me-1 align-text-bottom"></i>Logout</a
          >
        </div>
      </li>
      <!-- <button class="nav-link button-menu-mobile nav-icon" (click)="homePageLink()">
        <i class="fas fa-home"></i>
      </button> -->

      <li class="notification-list">
        <a
          class="nav-link arrow-none nav-icon offcanvas-btn"
          href="#"
          data-bs-toggle="offcanvas"
          data-bs-target="#Appearance"
          role="button"
          aria-controls="Rightbar"
        >
          <i class="ti ti-adjustments-horizontal"></i>
        </a>
      </li>
    </ul>
    <ul class="list-unstyled topbar-nav mb-0">
      <li>
        <img src="assets/images/logomed.png" alt="" width="100" />
      </li>

      <!-- <li>
        <button class="nav-link button-menu-mobile nav-icon" id="togglemenu" (click)="toggleMenu()">
          <i class="ti ti-menu-2"></i>
        </button>
      </li> -->
      <!-- <li class="hide-phone app-search">
        <h3  style="font-size: 20px;">Hospital : {{slot.hospital}} | Date : {{slot.date | date: 'dd/MM/yyyy'}} | Slot : {{slot.start_time}} -
          {{slot.end_time}}</h3> -->
      <!-- <form role="search" action="#" method="get">
          <input type="search" name="search" class="form-control top-search mb-0" placeholder="Type text...">
          <button type="submit"><i class="ti ti-search"></i></button>
        </form> -->
      <!-- </li> -->
    </ul>
  </nav>
</div>
<ng-template #changeHospital let-modal>
  <div class="modal-header d-flex justify-content-between">
    <div>
      <h4 class="modal-title" id="modal-basic-title">Select Hospital</h4>
    </div>
    <div class="control-button">
      <button
        type="button"
        class="btn btn-sm btn-outline-secondary"
        (click)="modal.close('Save click')"
      >
        <i class="ti ti-circle-x main"></i>
      </button>
    </div>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label>Appointment Date <span class="text-danger">*</span></label>
      <input type="date" class="form-control" [(ngModel)]="current_date" (ngModelChange)="resetSelects()">
    </div>

    <div class="form-group mt-3">
      <label>Hospital <span class="text-danger">*</span></label>
      <select class="form-select" [(ngModel)]="selected_hospital" (ngModelChange)="getTimeSlots()">
        <option selected value="Select Hopsital">Select Hospital</option>
        <option *ngFor="let item of hospitals" [value]="item.channeling_center_name">{{ item.channeling_center_name }}</option>
      </select>
    </div>

    <div class="form-group mt-3">
      <label>Slot <span class="text-danger">*</span></label>
      <select class="form-select" [(ngModel)]="selected_slot">
        <option selected value="Select Slot">Select Slot</option>
        <option *ngFor="let item of slot_list" [value]="item._id">{{ item.start_time }} - {{ item.end_time }}</option>
      </select>
    </div>

    <div class="d-grid mt-3">
      <button class="btn btn-primary" (click)="loadAppintments()">Load Appointments</button>
    </div>

  </div>


</ng-template>

<ng-template #modalData let-modal>
  <div class="modal-header d-flex justify-content-between">
    <div>
      <h4 class="modal-title" id="modal-basic-title">Create New Appointment</h4>
    </div>
    <div class="control-button">
      <button
        type="button"
        class="btn btn-sm btn-outline-secondary"
        (click)="modal.close('Save click')"
      >
        <i class="ti ti-circle-x main"></i>
      </button>
      <button
        type="button"
        class="btn btn-sm btn-outline-secondary"
        [disabled]="!patients_form.valid"
        (click)="createNewAndLink(false)"
      >
        <i class="ti ti-device-floppy main"></i>
      </button>
      <button
        type="button"
        class="btn btn-sm btn-outline-secondary"
        [disabled]="!patients_form.valid"
        (click)="createNewAndLink(true)"
      >
        <i class="ti ti-player-track-next main"></i>
      </button>
    </div>
  </div>
  <div class="modal-body">
    <div class="card-body">
      <div class="card text-center">
        <div class="card-header">
          <h4 class="card-title">Slot Information</h4>
        </div>
        <!--end card-header-->
        <div class="card-body">
          <p class="card-text text-muted">
            Hospital : {{ slot.hospital }} | Date :
            {{ slot.date | date : "dd/MM/yyyy" }} | Slot :
            {{ slot.slot.start_time }} - {{ slot.slot.end_time }}
          </p>
        </div>
        <!--end card-body-->
      </div>
      <ul class="nav nav-pills nav-justified" role="tablist">
        <li class="nav-item waves-effect waves-light">
          <a
            class="nav-link active"
            data-bs-toggle="tab"
            href="#home-1"
            role="tab"
            aria-selected="true"
            >Existing Patient</a
          >
        </li>
        <li class="nav-item waves-effect waves-light">
          <a
            class="nav-link"
            data-bs-toggle="tab"
            href="#profile-1"
            role="tab"
            aria-selected="false"
            >New Patient</a
          >
        </li>
      </ul>
      <div class="tab-content">
        <div class="tab-pane p-3 active" id="home-1" role="tabpanel">
          <div class="input-group mb-3">
            <input
              type="email"
              class="form-control"
              placeholder="Search by Name, Phone No, Med ID, NIC or Clinic No"
              [(ngModel)]="keyword"
            />
            <button
              class="btn btn-secondary"
              type="button"
              id="button-addon2"
              (click)="search()"
            >
              Search
            </button>
          </div>
          <!-- <div class="table-responsive"> -->
          <table class="table">
            <thead>
              <tr>
                <th>Med Id</th>
                <th>Patient Name</th>
                <th>Clinic No</th>
                <th>NIC</th>
                <th>Phone</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let item of search_results
                    | paginate
                      : {
                          itemsPerPage: tableSize,
                          currentPage: page,
                          totalItems: count
                        };
                  let i = index
                "
              >
                <th scope=" row">{{ item.patient_id }}</th>
                <td>{{ item.name }}</td>
                <td>{{ item.clinic_no }}</td>
                <td>{{ item.nic }}</td>
                <td>{{ item.mobile }}</td>
                <td>
                  <button
                    class="btn btn-sm btn-primary"
                    (click)="updateAppointment(item)"
                  >
                    Create
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="d-flex justify-content-center">
            <pagination-controls
              [responsive]="true"
              previousLabel="Prev"
              nextLabel="Next"
              (pageChange)="onTableDataChange($event)"
            >
            </pagination-controls>
          </div>
          <!-- </div> -->
        </div>
        <div class="tab-pane p-3" id="profile-1" role="tabpanel">
          <form [formGroup]="patients_form" class="form-styles-popup">
            <div class="form-group row mb-2">
              <label>Name </label>
              <div class="col-lg-10">
                <input
                  placeholder="Enter Name"
                  name="name"
                  type="text"
                  class="form-control"
                  formControlName="name"
                />
                <div
                  class="wrning-tag"
                  *ngIf="patients_form.controls['name'].invalid"
                >
                  Please Enter Name*
                </div>
              </div>
            </div>
            <div class="form-group row mb-2">
              <label>Clinic No </label>
              <div class="col-lg-7">
                <input
                  placeholder="Enter Clinic No"
                  name="clinic_no"
                  type="text"
                  class="form-control"
                  formControlName="clinic_no"
                />
              </div>
            </div>
            <div class="form-group row mb-2">
              <label>NIC </label>
              <div class="col-lg-10">
                <input
                  placeholder="Enter NIC"
                  name="nic"
                  type="text"
                  class="form-control"
                  formControlName="nic"
                  (change)="validationsCheck($event, 'nic')"
                />
                <div class="wrning-tag" *ngIf="id_number_validation">
                  Please Enter Valid NIC Number*
                </div>
              </div>
            </div>
            <div class="form-group row mb-2">
              <label>Phone Number </label>
              <div class="col-lg-10">
                <input
                  placeholder="Enter Mobile"
                  name="mobile"
                  type="number"
                  class="form-control"
                  min="0"
                  oninput="this.value = Math.abs(this.value)"
                  formControlName="mobile"
                  (change)="validationsCheck($event, 'mobile_number')"
                />
                <div class="wrning-tag" *ngIf="phone_number_validation">
                  Please Enter Valid Phone Number*
                </div>
              </div>
            </div>
            <div class="form-group row mb-2">
              <label>DOB </label>
              <div class="col-lg-10">
                <input
                  placeholder="Enter Department"
                  name="dob"
                  type="date"
                  class="form-control"
                  formControlName="dob"
                />
              </div>
            </div>
            <div class="form-group row mb-2">
              <label>Gender</label>
              <div class="col-lg-10">
                <select
                  class="form-select"
                  formControlName="gender"
                  placeholder="Select Gender"
                >
                  <option value="M">M</option>
                  <option value="F">F</option>
                  <option value="-" selected>-</option>
                </select>
              </div>
            </div>
            <div class="form-group row mb-2">
              <label>Address</label>
              <div class="col-lg-10">
                <input
                  placeholder="Enter Address"
                  name="address"
                  type="text"
                  class="form-control"
                  formControlName="address"
                />
              </div>
            </div>
            <div class="form-group row mb-2">
              <label>City </label>
              <div class="col-lg-10">
                <input
                  placeholder="Enter City"
                  name="city"
                  type="city"
                  class="form-control"
                  formControlName="city"
                />
              </div>
            </div>
            <div class="form-group row mb-2">
              <label>Country </label>
              <div class="col-lg-10">
                <select2
                  [data]="filtered_countries_select"
                  [value]="countrySelected"
                  formControlName="country"
                  placeholder="Select country"
                ></select2>
              </div>
            </div>
            <div class="form-group row mb-2">
              <div class="col-lg-3"></div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</ng-template>
