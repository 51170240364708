<div class="d-flex justify-content-between">
    <div>
        <h2 mat-dialog-title>Area Of Ausculations - Heart
        </h2>
    </div>
    <div class="control-button">
        <button mat-button mat-dialog-close type="button" class="btn btn-sm  btn-outline-primary">
            <i class="ti ti-circle-x"></i>
        </button>
        <button type="button" class="btn btn-sm  btn-outline-primary">
            <i class="ti ti-device-floppy" (click)="save()"></i>
        </button>
    </div>
</div>
<mat-dialog-content class="mat-typography">
    <form [formGroup]="auscultation_form">
        <div class="row">
            <div class="col-md-4">
                <div class="form-group row mb-2">
                    <label>Name </label>
                    <div class="col-lg-12">
                        <input type="text" placeholder="Enter Name" formControlName="name" class="form-control">
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group row mb-2">
                    <label>Date </label>
                    <div class="col-lg-12">
                        <input type="date" placeholder="Enter Date" formControlName="date" class="form-control">
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group row mb-2">
                    <label>Selections </label>
                    <div class="col-md-12">
                        <select formControlName="selections" class="form-select">
                            <option></option>
                      <option>Normal</option>
                      <option>Continuous Murmur</option>
                      <option>Bruit</option>
                      <option>Audible murmur</option>
                      <option>Systolic Murmur</option>
                      <option>Ejection Systolic Murmurs</option>
                      <option>Pan Systolic Murmurs</option>
                      <option>Early Diastolic Murmurs</option>
                      <option>Mid Diastolic Murmurs</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </form>
</mat-dialog-content>