import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastService } from 'src/app/core/services/misc/toast.service';

@Component({
  selector: 'app-ausculation',
  templateUrl: './ausculation.component.html',
  styleUrls: ['./ausculation.component.scss']
})
export class AusculationComponent implements OnInit {

  auscultation_form = new FormGroup({
    name: new FormControl('', Validators.compose([Validators.required,])),
    date: new FormControl(''),
    selections: new FormControl(''),
  });

  constructor(
    private matDialogRef: MatDialogRef<any>,
    private toast: ToastService,
  ) { }

  ngOnInit(): void {
  }

  save() {
    if (this.auscultation_form.value.name) {
      const object = this.auscultation_form.value;
      object.is_default = false
      object.selected = true
      this.matDialogRef.close(object);
    }else{
      this.toast.showError('Please Enter Name');
    }

  }
}
