<div class="d-flex justify-content-between">
    <div>
        <h2 mat-dialog-title>Allergies</h2>
    </div>
    <div class="control-button">
        <button mat-button mat-dialog-close type="button" class="btn btn-sm  btn-outline-primary">
            <i class="ti ti-circle-x"></i>
        </button>
        <button type="button" class="btn btn-sm  btn-outline-primary">
            <i class="ti ti-device-floppy" (click)="save()"></i>
        </button>
    </div>
</div>
<mat-dialog-content class="mat-typography">
    <form [formGroup]="allergies_form">
        <div class="row">
            <div class="col-md-3">
                <div class="form-group row mb-2">
                    <label>Name </label>
                    <div class="col-lg-12">
                        <input type="text" placeholder="Enter Name" formControlName="name" class="form-control">
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group row mb-2">
                    <label> Value </label>
                    <div class="col-md-12">
                        <input type="text" placeholder="Enter Value" formControlName="value" class="form-control">
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group row mb-2">
                    <label> Duration </label>
                    <div class="col-md-12">
                        <select class="form-select" formControlName="duration" >
                            <option value=""></option>
                            <option value="Minutes">Minutes</option>
                            <option value="Hours">Hours</option>
                            <option value="Days">Days</option>
                            <option value="Weeks">Weeks</option>
                            <option value="Months">Months</option>
                            <option value="Years">Years</option>
                          </select>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group row mb-2">
                    <label> Remarks </label>
                    <div class="col-md-12">
                        <input type="text" placeholder="Enter Remarks" formControlName="remarks" class="form-control">
                    </div>
                </div>
            </div>
        </div>
    </form>
</mat-dialog-content>