<div class="d-flex justify-content-between">
    <div>
        <h2 mat-dialog-title>Family History</h2>
    </div>
    <div class="control-button">
        <button mat-button mat-dialog-close type="button" class="btn btn-sm  btn-outline-primary">
            <i class="ti ti-circle-x"></i>
        </button>
        <button type="button" class="btn btn-sm  btn-outline-primary">
            <i class="ti ti-device-floppy" (click)="save()"></i>
        </button>
    </div>
  </div>
  <mat-dialog-content class="mat-typography">
    <form [formGroup]="family_data_form">
        <div class="row">
            <div class="col-md-3">
                <div class="form-group row mb-2">
                    <label>Disease </label>
                    <div class="col-lg-12">
                        <input type="text" placeholder="Enter Disease" formControlName="name" class="form-control">
                    </div>
                </div>
            </div>
            <div class="col-md-3">
              <div class="form-group row mb-2">
                  <label>Date </label>
                  <div class="col-lg-12">
                      <input type="date" placeholder="Enter Name" formControlName="date" class="form-control">
                  </div>
              </div>
          </div>
            <div class="col-md-3">
            <div class="form-group row mb-2">
                <label>Relationship </label>
                <div class="col-lg-12">
                    <select class="form-control" formControlName="relationship" >
                    <option value=""> Select Relationship</option>
                    <option value="1st degree Relatives">1st degree Relatives</option>
                    <option value="2nd degree Relatives">2nd degree Relatives</option>
                    <option value="3rd degree Relatives">3rd degree Relatives</option>
                  </select>
                </div>
            </div>
        </div>
        <div class="col-md-3">
          <div class="form-group row mb-2">
              <label>Remarks </label>
              <div class="col-lg-12">
                  <input type="text" placeholder="Enter remarks" formControlName="remarks" class="form-control">
              </div>
          </div>
      </div>
        </div>
    </form>
  </mat-dialog-content>
  