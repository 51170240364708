<div class="d-flex justify-content-between">
    <div>
        <h2 mat-dialog-title> Clinical Impress </h2>
    </div>
    <div class="control-button">
      <button mat-button mat-dialog-close type="button" class="btn btn-sm  btn-outline-primary">
        <i class="ti ti-circle-x"></i>
      </button>
      <button type="button" class="btn btn-sm  btn-outline-primary">
        <i class="ti ti-device-floppy" (click)="save()"></i>
      </button>
    </div>
  </div>
<mat-dialog-content class="mat-typography">
    <form [formGroup]="clinical_impress_form">
        <div class="row">
            <div class="col-md-4">
                <div class="form-group row mb-2">
                    <label> Name </label>
                    <div class="col-md-12">
                        <input type="text" formControlName="name" placeholder="Enter Name" class="form-control">
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group row mb-2">
                    <label>Date </label>
                    <div class="col-lg-12">
                        <input placeholder="Enter Date" type="date" formControlName="date" class="form-control">
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group row mb-2">
                    <label>Remarks </label>
                    <div class="col-lg-12">
                        <input placeholder="Enter Remarks" type="text" formControlName="remarks" class="form-control">
                    </div>
                </div>
            </div>
        </div>
    </form>
</mat-dialog-content>
