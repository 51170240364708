import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent implements OnInit {

  window = window;
  sidebar_hide = false;
  
  constructor(
    private route: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    console.log(this.sidebar_hide)
  }


  showSideBar(value) {
    this.sidebar_hide = value
  }
}
