import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastService } from 'src/app/core/services/misc/toast.service';

@Component({
  selector: 'app-add-allergies',
  templateUrl: './add-allergies.component.html',
  styleUrls: ['./add-allergies.component.scss']
})
export class AddAllergiesComponent implements OnInit {
  allergies_form = new FormGroup({
    name: new FormControl('', Validators.compose([Validators.required,])),
    remarks: new FormControl(''),
    duration: new FormControl(''),
    value: new FormControl(''),
  });

  constructor(
    private matDialogRef: MatDialogRef<any>,
    private toast: ToastService,
  ) { }

  ngOnInit(): void {
  }

  save() {
    if (this.allergies_form.value.name) {
      const object = this.allergies_form.value;
      object.is_default = false
      object.selected = true
      this.matDialogRef.close(object);
    }else{
      this.toast.showError('Please Enter Name');
    }

  }
}

