import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { CoreModule } from './core/core.module';
import { AppRoutingModule } from './app-routing.module';
import { Select2Module } from 'ng-select2-component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatDialogModule} from '@angular/material/dialog';
import { AppComponent } from './app.component';
import {AdminLayoutComponent} from './resources/layouts/admin-layout/admin-layout.component';
import {AuthLayoutComponent} from './resources/layouts/auth-layout/auth-layout.component';
import {ComponentsModule} from './resources/components/components.module';
import { HotToastModule } from '@ngneat/hot-toast';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatInputModule} from '@angular/material/input';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { TagInputModule } from 'ngx-chips';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {MatExpansionModule} from '@angular/material/expansion';
import {AuthInterceptor} from './core/interceptors/auth.interceptor';
import { ResetEmailComponent } from './pages/auth/reset-email/reset-email.component';
import { OtpInvalidateComponent } from './pages/auth/otp-invalidate/otp-invalidate.component';
import { CheckOtpComponent } from './pages/auth/check-otp/check-otp.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { ReferralModalComponent } from './pages/dialogs/referral-modal/referral-modal.component';
import { QuickPackServiceComponent } from './pages/dialogs/quick-pack-service/quick-pack-service.component';
import { MasterDataComponent } from './pages/dialogs/master-data/master-data.component';
import { FormsLayoutComponent } from './resources/layouts/forms-layout/forms-layout.component';
import { CoronaryAngiogramComponent } from './pages/dialogs/coronary-angiogram/coronary-angiogram.component';
import { AddAllergiesComponent } from './pages/dialogs/add-allergies/add-allergies.component';
import { AddLungsComponent } from './pages/dialogs/add-lungs/add-lungs.component';
import { CtAngiogramComponent } from './pages/dialogs/ct-angiogram/ct-angiogram.component';
import { ReferredByComponent } from './pages/dialogs/referred-by/referred-by.component';
import { FamilyHistoryComponent } from './pages/dialogs/family-history/family-history.component';
import { SocialHistoryComponent } from './pages/dialogs/social-history/social-history.component';
import { AusculationComponent } from './pages/dialogs/ausculation/ausculation.component';
import { ClinicalImpressComponent } from './pages/dialogs/clinical-impress/clinical-impress.component';
import { DifferentialDiagnosisComponent } from './pages/dialogs/differential-diagnosis/differential-diagnosis.component';
import { EcoCardiogramComponent } from './pages/dialogs/eco-cardiogram/eco-cardiogram.component';

@NgModule({
  declarations: [AppComponent, AdminLayoutComponent, AuthLayoutComponent, ResetEmailComponent, OtpInvalidateComponent, CheckOtpComponent, ReferralModalComponent, QuickPackServiceComponent, MasterDataComponent, FormsLayoutComponent, CoronaryAngiogramComponent, AddAllergiesComponent, AddLungsComponent, CtAngiogramComponent, ReferredByComponent, FamilyHistoryComponent, SocialHistoryComponent, AusculationComponent, ClinicalImpressComponent, DifferentialDiagnosisComponent, EcoCardiogramComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        MatCheckboxModule,
        MatDialogModule,
        MatAutocompleteModule,
        MatInputModule,
        FormsModule,
        HttpClientModule,
        CoreModule,
        AppRoutingModule,
        ComponentsModule,
        ReactiveFormsModule,
        Select2Module,
        AutocompleteLibModule,
        TagInputModule,
        AngularEditorModule,
        NgbModule,
        HotToastModule.forRoot(),
        MatExpansionModule,
    ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
