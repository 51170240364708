import {Injectable} from '@angular/core';
import {ToastService} from '../misc/toast.service';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class PatientDataService {
  constructor(
    private http: HttpClient,
    private toast: ToastService,
  ) {
  }

  create(request_body) {
    return new Promise(resolve => {
      this.http.post(`${environment.api_url}/patient-data/create`, request_body).subscribe(res => {
        resolve(res);
      });
    });
  }


  update( update_object,document_id) {
    console.log(document_id, update_object)
    const request_body = {
      document_id: document_id,
      input_data: update_object
    };
    
    return new Promise(resolve => {
      this.http.put(`${environment.api_url}/patient-data/update`, request_body).subscribe(res => {
        // @ts-ignore
        resolve(res);
      });
    });
  }

  list() {
    return new Promise(resolve => {
      this.http.get(`${environment.api_url}/patient-data`).subscribe(res => {
        resolve(res);
      });
    });
  }

  delete(request_body) {
    return new Promise(resolve => {
      this.http.delete(`${environment.api_url}/patient-data/delete/${request_body}`).pipe(
        this.toast.observe('Record Deleting..', 'Successfully Deleted', 'Something Went Wrong')
      ).subscribe(res => {
        // @ts-ignore
        resolve(res.data);
      });
    });
  }

  deleteQuery(request_body) {
    return new Promise(resolve => {
      this.http.post(`${environment.api_url}/patient-data/delete/query`, request_body).subscribe(res => {
        // @ts-ignore
        resolve(res.data);
      });
    });
  }

  show(request_body) {
    console.log(request_body)
    return new Promise(resolve => {
      this.http.get(`${environment.api_url}/patient-data/show/${request_body}`).subscribe(res => {
        console.log(res)
        resolve(res);
      });
    });
  }

  visitData(visit_id) {
    return new Promise(resolve => {
      this.http.get(`${environment.api_url}/patient-data/visits/${visit_id}`).subscribe(res => {
        console.log(res)
        resolve(res);
      });
    });
  }

  getVisitHistory(patient_id) {
    return new Promise(resolve => {
      this.http.get(`${environment.api_url}/patient-data/visits/all/${patient_id}`).subscribe(res => {
        // @ts-ignore
        res = res.sort((a, b) => (a.created_at > b.created_at ? -1 : 1));
        // @ts-ignore
        if (res.length > 0) {
          resolve(res[0]);
        } else {
          resolve('NA');
        }
      });
    });
  }

  
  // getVisitHistory(patient_id) {
  //   return new Promise(resolve => {
  //     this.http.get(`${environment.api_url}/patient-data/visits/all/${patient_id}`).subscribe(res => {
  //       // @ts-ignore
  //       res = res.sort((a, b) => (a.created_at > b.created_at ? -1 : 1));
  //       // @ts-ignore
  //       if (res.length > 0) {
  //         resolve(res[0]._id);
  //       } else {
  //         resolve('NA');
  //       }
  //     });
  //   });
  // }
  createWithOutToast(request_body) {
    return new Promise(resolve => {
      this.http.post(`${environment.api_url}/patient-data/create`, request_body).pipe(
      ).subscribe(res => {
        resolve(res);
      });
    });
  }

}
