<div class="d-flex justify-content-between">
    <div>
        <h2 mat-dialog-title>Echo Cardiogram</h2>
    </div>
    <div class="control-button">
        <button mat-button mat-dialog-close type="button" class="btn btn-sm  btn-outline-primary">
            <i class="ti ti-circle-x"></i>
        </button>
        <button type="button" class="btn btn-sm  btn-outline-primary">
            <i class="ti ti-device-floppy" (click)="save()"></i>
        </button>
    </div>
  </div>
  <mat-dialog-content class="mat-typography">
    <form [formGroup]="echo_cardiogram_form">
        <div class="row">
            <div class="col-md-4">
                <div class="form-group row mb-2">
                    <label>Name </label>
                    <div class="col-lg-12">
                        <input type="text" placeholder="Enter Name" formControlName="name" class="form-control">
                    </div>
                </div>
            </div>
            <div class="col-md-4">
              <div class="form-group row mb-2">
                  <label>Position </label>
                  <div class="col-lg-12">
                    <select class="form-control" formControlName="value2" >
                        <option value="-">-</option>
                      <option value="Ostial">Ostial</option>
                      <option value="Proximal">Proximal</option>
                      <option value="Mid">Mid</option>
                      <option value="Distal">Distal</option>
                      </select>
                  </div>
              </div>
          </div>
            <div class="col-md-4">
            <div class="form-group row mb-2">
                <label>Severity </label>
                <div class="col-lg-12">
                    <select class="form-control" formControlName="value3" >
                        <option value="-">-</option>
                        <option value="Mild">Mild</option>
                        <option value="Moderate">Moderate</option>
                        <option value="Severe">Severe</option>
                  </select>
                </div>
            </div>
        </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <div class="form-group row mb-2">
                    <label>Disease </label>
                    <div class="col-lg-12">
                        <select class="form-control" formControlName="value1" >
                            <option value="-">-</option>
                            <option value="Normal">Normal</option>
                            <option value="Disease">Disease</option>
                      </select>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
              <div class="form-group row mb-2">
                  <label>Date </label>
                  <div class="col-lg-12">
                      <input type="date"  formControlName="date" class="form-control">
                  </div>
              </div>
          </div>
            <div class="col-md-4">
            <div class="form-group row mb-2">
                <label>Relationship </label>
                <div class="col-lg-12">
                    <input type="text" placeholder="Enter Remarks" formControlName="remarks" class="form-control">
                </div>
            </div>
        </div>
        </div>
    </form>
  </mat-dialog-content>
  