<div class="d-flex justify-content-between">
    <div>
      <h6 >{{ data.type }}</h6>
    </div>
    <div class="control-button">
      <button mat-button mat-dialog-close type="button" class="btn btn-sm  btn-outline-primary">
        <i class="ti ti-circle-x"></i>
      </button>
      <button  type="button" class="btn btn-sm  btn-outline-primary">
        <i class="ti ti-square-plus" (click)="showCreateInput()"></i>
      </button>
      <button type="button" class="btn btn-sm  btn-outline-primary"  [disabled]="show_create_input==false">
        <i class="ti ti-device-floppy" (click)="save()"></i>
      </button>
    </div>
  </div>
  <mat-dialog-content class="mat-typography">
   <div class="row">
     <div class="col-md-12 mb-2" *ngIf="show_create_input">
       <div class="form-group">
         <input type="text" class="form-control" placeholder="Enter New {{ data.type }}" [(ngModel)]="value">
       </div>
     </div>
     <hr *ngIf="show_create_input">
     <div class="col-md-6 mb-2">
      <div class="form-group">
        <input type="text" class="form-control" placeholder="Search" [(ngModel)]="search_value" (ngModelChange)="onFilterChange()">
      </div>
    </div>
     <!-- <hr> -->
     <div class="col-md-12 mt-2">
       <div class="table-responsive">
         <table class="table table-sm table-striped table-hover">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col" class="d-flex justify-content-end">Action</th>
            </tr>
          </thead>
           <tbody>
           <tr *ngFor="let item of data_array; index as i" class="master-data-item">
             <ng-container *ngIf="item.name">
             <td width="" class="master-data-td">
               <div *ngIf="!item.editing">
                 {{ item.name }}
               </div>
               <input *ngIf="item.editing" type="text" class="form-control" [(ngModel)]="item.name">
             </td>
             <td>
               <div class="d-flex justify-content-end">
                 <button *ngIf="!item.editing" type="button" class="btn btn-sm  btn-outline-primary"
                         (click)="editMasterDataItem(item)">
                   <i class="ti ti-edit main colord"></i>
                 </button>
                 <button *ngIf="item.editing" type="button" class="btn btn-sm  btn-outline-primary"
                         (click)="updateMasterDataItem(item)">
                   <i class="ti ti-device-floppy main colord"></i>
                 </button>
                 <button type="button" class="btn btn-sm  btn-outline-secondary"
                         (click)="deleteMasterDataItem(item, i)">
                   <i class="ti ti-trash main colord"></i>
                 </button>
               </div>
             </td>
             </ng-container>
           </tr>
           </tbody>
         </table>
       </div>
     </div>
   </div>
  </mat-dialog-content>
  