import { Component, OnInit } from '@angular/core';
import {FormGroup, FormControl,Validators} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import { ToastService } from 'src/app/core/services/misc/toast.service';

@Component({
  selector: 'app-eco-cardiogram',
  templateUrl: './eco-cardiogram.component.html',
  styleUrls: ['./eco-cardiogram.component.scss']
})
export class EcoCardiogramComponent implements OnInit {
  echo_cardiogram_form = new FormGroup({
    name: new FormControl('', Validators.compose([ Validators.required,])),
    value1: new FormControl(''),
    value2: new FormControl(''),
    value3: new FormControl(''),
    date: new FormControl(''),
    remarks: new FormControl(''),
  });

  constructor(
    private matDialogRef: MatDialogRef<any>,
    private toast: ToastService,
    ) {}

  ngOnInit(): void {
  }

  save() {
    if(this.echo_cardiogram_form.value.name){
      const object = this.echo_cardiogram_form.value;
      object.is_default = false
      object.selected = true
      this.matDialogRef.close(object);
    }else{
      this.toast.showError('Please Enter Name');
    }

  }
}
